import React from "react"
import { Navbar, Nav, Dropdown, Modal, Button } from "react-bootstrap"
import { useHistory, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../actions"
import { BrowserView, MobileView, isMobile } from "react-device-detect"

export default function Navigation() {
    const [expanded, setExpanded] = React.useState(false)
    const [showModalLogout, setShowModalLogout] = React.useState(false)

    const dispatch = useDispatch()
    const history = useHistory()
    const handleLogout = () => {
        setShowModalLogout(false)
        setExpanded(false)
        dispatch(logout())
        history.push("/login")
    }
    const handleSetExpanded = (link) => {
        setExpanded(false)
        // if (link !== "dashboard") {
            // setTimeout(function () {
                history.push(link)
            // }, 1000);
        // } else {
            // history.push("dashboard")
        // }
    }

    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })

    return (
        <>
            <Navbar expanded={expanded} expand="lg" className="boxShadow" style={{ background: "white", padding: isMobile ? "13px 25px" : "0px 15vw 0px 0px", minHeight: "4.383vw", position: "sticky", top: 0, zIndex: 999 }}>
                <Navbar.Brand style={{ marginTop: -5, marginLeft: !isMobile && 20 }}><img width="42" height="42" src="https://s3-ap-southeast-1.amazonaws.com/arsitag-assets/ab/logo-header.svg" alt="asd" /></Navbar.Brand>
                {data &&
                    <>
                        <Navbar.Toggle className="shadow-none" onClick={() => setExpanded(!expanded)} />
                        <Navbar.Collapse>
                            <Nav className="me-auto">
                                <MobileView>
                                    {data.role_id !== 7 &&
                                        <>
                                            <Nav.Link onClick={handleSetExpanded} className="navLink mobileAlignRight" as={Link} to="/disbursement">Disbursement</Nav.Link>
                                            <Nav.Link onClick={handleSetExpanded} className="navLink mobileAlignRight" as={Link} to="/invoice">Invoice</Nav.Link>
                                        </>
                                    }
                                    <Nav.Link onClick={handleSetExpanded} className="navLink mobileAlignRight" as={Link} to="/project">Project</Nav.Link>
                                    <Nav.Link className="navLink mobileAlignRight" onClick={() => setShowModalLogout(true)}>Logout</Nav.Link>
                                </MobileView>
                            </Nav>
                            <BrowserView>
                                <Nav>
                                    <Dropdown className="mobileAlignRight">
                                        <Dropdown.Toggle className="shadow-none ButtonHover" variant="transparent" id="nav-dropdown" >
                                            <i className="fas fa-user-circle fa-2x" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="removeHighlight" style={{ margin: "-5px 0px 0px -110px"}}>
                                            <Dropdown.Header className="navLink">{data.login_email}</Dropdown.Header>
                                            <Dropdown.Divider />
                                            <Dropdown.Item className="navLink" onClick={handleLogout}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </BrowserView>
                        </Navbar.Collapse>
                    </>
                }
            </Navbar>
            <BrowserView>
                {data &&
                    <div className="d-flex flex-column align-items-center sidebarLinks" style={{ left: "-11.7vw" }}>
                        <Nav.Link onClick={() => handleSetExpanded("dashboard-general")} className="d-flex justify-content-between" style={{ color: "black" }}>
                            <span><i className="fas fa-home"></i></span>
                            <span>Dashboard</span>
                            <span><i className="fas fa-home hideOnHover"></i></span>
                        </Nav.Link>
                        <div style={{ width: "100%"}}>
                            <Nav.Link onClick={() => handleSetExpanded("dashboard-general")}>
                                <span>General</span>
                            </Nav.Link>
                            <Nav.Link onClick={() => handleSetExpanded("dashboard-marketplace")}>
                                <span>Marketplace</span>
                            </Nav.Link>
                            <Nav.Link onClick={() => handleSetExpanded("dashboard-project")}>
                                <span>Project</span>
                            </Nav.Link>
                        </div>
                        {data.role_id !== 7 &&
                            <>
                                <Nav.Link onClick={() => handleSetExpanded("disbursement")} className="d-flex justify-content-between">
                                    <span><i className="fas fa-columns"></i></span>
                                    <span>Disbursement</span>
                                    <span><i className="fas fa-columns hideOnHover"></i></span>
                                </Nav.Link>
                                <Nav.Link onClick={() => handleSetExpanded("invoice")} className="d-flex justify-content-between">
                                    <span><i className="fas fa-file-invoice"></i></span>
                                    <span>Invoice</span>
                                    <span><i className="fas fa-file-invoice hideOnHover"></i></span>
                                </Nav.Link>
                            </>
                        }
                        <Nav.Link onClick={() => handleSetExpanded("project")} className="d-flex justify-content-between">
                            <span><i className="fas fa-tasks"></i></span>
                            <span>Project</span>
                            <span><i className="fas fa-tasks hideOnHover"></i></span>
                        </Nav.Link>
                    </div>
                }
            </BrowserView>
            <Modal style={{ marginTop: 300 }} show={showModalLogout} onHide={() => setShowModalLogout(false)}>
                <Modal.Header><Modal.Title>Are you sure you want to log out?</Modal.Title></Modal.Header>
                <Modal.Footer style={{ borderTop: "1px solid transparent" }}>
                    <Button className="ButtonGrey" onClick={() => setShowModalLogout(false)}>Cancel</Button>
                    <Button className="ButtonRed" onClick={() => handleLogout()}>Logout</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}