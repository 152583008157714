import React from "react"
import Axios from "axios"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Button } from "react-bootstrap"
import { ResponsiveContainer, Line, Bar, XAxis, Tooltip, YAxis, ComposedChart } from "recharts"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ReactExport from "react-data-export"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

export default function DashboardGeneral() {
    const [mainData, setMainData] = React.useState([
        {
            title: "Total Registered Pros",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Pros" },
                { title: "Total New Pros" },
                { title: "Total Existing Pros" }
            ],
            data: []
        },
        {
            title: "Total Eligible Pros",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Pros" }
            ],
            data: []
        }
    ])
    const [totalRegisteredPros, setTotalEligiblePros] = React.useState(null)
    const [totalEligiblePros, setTotalRegisteredPros] = React.useState(null)
    const [totalVerified, setTotalVerified] = React.useState(null)
    const [totalPartner, setTotalPartner] = React.useState(null)
    const [dateRange, setDateRange] = React.useState(["", ""], ["", ""])
    const [startDate1, endDate1] = dateRange[0]
    const [startDate2, endDate2] = dateRange[1]

    const getData = (date) => {
        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/active-professional", date).then(res => {
            const res1 = res.data.data
            Axios.post("https://www.arsitag.com/api/v1/dashboard-general/new-active-professional", date).then(res => {
                let newArr = [...mainData]
                newArr[0].data = []
                res1.forEach((item, index) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    item.total_new_pros = res.data.data[index].total_new_pros
                    item.total_existing_pros = item.total_unique_pros - res.data.data[index].total_new_pros
                    newArr[0].data.push([
                        { value: item.year },
                        { value: item.month_name },
                        { value: item.total_unique_pros },
                        { value: item.total_new_pros },
                        { value: item.total_existing_pros }
                    ])
                })
                newArr[0].chart = res1
                setMainData(newArr)
            })
        })
    }
    const getData2 = (date) => {
        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/active-professional-v2", date).then(res => {
            let newArr = [...mainData]
                newArr[1].data = []
                res.data.data.forEach((item) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    newArr[1].data.push([
                        { value: item.year },
                        { value: item.month_name },
                        { value: item.total_unique_pros }
                    ])
                })
                newArr[1].chart = res.data.data
                setMainData(newArr)
        })
    }

    React.useEffect(() => {
        let newArr = [...mainData]
        newArr[0].chart = [
            { month_name_short: "Oct" },
            { month_name_short: "Nov" },
            { month_name_short: "Dec" },
            { month_name_short: "Jan" },
            { month_name_short: "Feb" }
        ]
        setMainData(newArr)

        const today = new Date()
        const dateTo = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)
        today.setMonth(today.getMonth() - 4)
        const dateFrom = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-01"
        const date = dateFrom + "&" + dateTo

        getData(date)
        getData2(date)

        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/total-pros").then(res => setTotalRegisteredPros((res.data.data[0].total_pros).toLocaleString()))
        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/total-active-pros").then(res => setTotalEligiblePros((res.data.data[0].total_active_pros).toLocaleString()))
        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/total-verified").then(res => setTotalVerified((res.data.data[0].total_verified)))
        Axios.post("https://www.arsitag.com/api/v1/dashboard-general/total-partner").then(res => setTotalPartner((res.data.data[0].total_partner)))
    }, [])

    const handleDateRange = (inputDate, index) => {
        let newRange = [...dateRange]
        newRange[index] = inputDate
        setDateRange(newRange)
        
        if (inputDate[1] !== null) {
            const dateArr = []
            for (let i=0; i<=1; i++) {
                const offset = inputDate[i].getTimezoneOffset()
                inputDate[i] = new Date(inputDate[i].getTime() - (offset * 60 * 1000))
                dateArr.push(inputDate[i].toISOString().split("T")[0])
            }
            if (dateArr.length === 2) {
                const tempYear = dateArr[1].slice(0, 4)
                const tempMonth = dateArr[1].slice(5, 7)[0] === "0" ? dateArr[1].slice(6, 7) : dateArr[1].slice(5, 7)
                dateArr[1] = new Date(tempYear, tempMonth, 0)
                const offset = dateArr[1].getTimezoneOffset()
                dateArr[1] = new Date(dateArr[1].getTime() - (offset * 60 * 1000))
                dateArr[1] = dateArr[1].toISOString().split("T")[0]
                const date = dateArr[0] + "&" + dateArr[1]
                if (index === 0) {
                    getData(date)
                } else {
                    getData2(date)
                }
            }
        }
    }

    const { userLoggedIn } = useSelector((state) => {
        return {
            userLoggedIn: state.user.data
        }
    })
    if (!userLoggedIn) return <Redirect to="/login" />

    return (
        <div style={{ background: "transparent" }}>
            <br />
            <div className="boxShadow" style={{ padding: "15px 40px", borderRadius: 10, background: "white" }}>
                <h2>General</h2>
                <hr style={{ margin: "15px -40px", color: "lightgrey", padding: 1 }} />
                <div className="d-flex justify-content-left" style={{ background: "transparent" }}>
                    <div className="boxShadow" style={{ background: "white", width: 200, padding: "10px 15px", borderRadius: 3, marginRight: 35 }}>
                        <div><b>Total Registered Pros</b></div>
                        <div>{totalRegisteredPros ? totalRegisteredPros : "-"}</div>
                    </div>
                    <div className="boxShadow" style={{ background: "white", width: 200, padding: "10px 15px", borderRadius: 3, marginRight: 35 }}>
                        <div><b>Total Eligible Pros</b></div>
                        <div>{totalEligiblePros ? totalEligiblePros : "-"}</div>
                    </div>
                    <div className="boxShadow" style={{ background: "white", width: 200, padding: "10px 15px", borderRadius: 3, marginRight: 35 }}>
                        <div><b>Total Verified</b></div>
                        <div>{totalVerified ? totalVerified : "-"}</div>
                    </div>
                    <div className="boxShadow" style={{ background: "white", width: 200, padding: "10px 15px", borderRadius: 3, marginRight: 35 }}>
                        <div><b>Total Partner</b></div>
                        <div>{totalPartner ? totalPartner : "-"}</div>
                    </div>
                </div>
            </div>
            <br />
            {mainData.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="boxShadow" style={{ height: "60vh", width: "75vw", padding: "25px 40px", borderRadius: 10, background: "white" }}>
                            <h4 className="text-center">{item.title}</h4>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-between">
                                    <DatePicker className="boxShadow" 
                                        selectsRange={true}
                                        startDate={index === 0 ? startDate1 : startDate2}
                                        endDate={index === 0 ? endDate1 : endDate2}
                                        onChange={(update) => handleDateRange(update, index)}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                        placeholderText="Select start and end date"
                                        minDate={new Date("01-01-2022")} maxDate={new Date()}
                                    />
                                </div>
                                <ExcelFile element={<Button style={{ width: "160px" }} className="shadow-none ButtonRed">Export (.xlsx)</Button>} filename={item.title}>
                                    <ExcelSheet dataSet={[item]} name="Sheet1" />
                                </ExcelFile>
                            </div>
                            <ResponsiveContainer width="100%" height="75%">
                                <ComposedChart data={item.chart} margin={{ top: 50 }}>
                                    <XAxis dataKey="month_name_short" />
                                    <YAxis />
                                    {/* <Tooltip separator=": " formatter={(value, name) => {
                                        if (name === "total_unique_pros") {
                                            return [value, "Active Pros"]
                                        }
                                    }} /> */}
                                    <Tooltip separator=": " formatter={(value, name) => [value, name.split("_").map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ")]} />
                                    {/* <Bar stackId="a" barSize={50} dataKey="total_unique_pros" fill="salmon" /> */}
                                    {
                                        index === 0 ? <>
                                            <Bar stackId="a" barSize={50} dataKey="total_existing_pros" fill="grey" />
                                            <Bar stackId="a" barSize={50} dataKey="total_new_pros" fill="lightgrey" />
                                        </> :
                                            <Bar stackId="a" barSize={50} dataKey="total_unique_pros" fill="grey" />
                                    }
                                    <Line dataKey="total_unique_pros" type="monotone" stroke="salmon" strokeWidth={2} />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                        <br />
                    </div>
                )
            })}
        </div>
    )
}