import React from "react"
import firebase from "../firebase"
import { Table, Button, Modal, Dropdown, Form, InputGroup } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { isBrowser } from "react-device-detect"
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis } from "recharts"

import ReactExport from "react-data-export"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default function Project() {
    const [mainData, setMainData] = React.useState([])
    const [headers, setHeaders] = React.useState([])
    const [projects, setProjects] = React.useState(null)
    const [projectsAll, setProjectsAll] = React.useState([])
    const [invoices, setInvoices] = React.useState([])
    const [disbursements, setDisbursements] = React.useState([])
    const [taxes, setTaxes] = React.useState([])
    const [files, setFiles] = React.useState([])
    const [viewDetails, setViewDetails] = React.useState({})
    const [showModalInfo, setShowModalInfo] = React.useState(false)
    const [showModalStatus, setShowModalStatus] = React.useState(false)
    const [showModalCounter, setShowModalCounter] = React.useState(false)
    const [followUpData, setFollowUpData] = React.useState([])
    const [firstStatus, setFirstStatus] = React.useState(null)
    const [secondStatus, setSecondStatus] = React.useState(null)
    const [notes, setNotes] = React.useState("")
    const [count] = React.useState([
        { name: "Ongoing", value: 0 },
        { name: "Idle-FU", value: 0 },
        { name: "Completed-FU", value: 0 },
        { name: "Completed", value: 0 },
        { name: "Closed", value: 0 },
        { name: "Deleted", value: 0 }
    ])
    const [toggleCount, setToggleCount] = React.useState(false)
    const [filter, setFilter] = React.useState(true)

    const getProjectsData = (a = "dateCreated", b = ">", c = "2017-01-01 00:00:00") => {
        firebase
            .firestore()
            .collection("projects")
            .where(a, b, c)
            .onSnapshot((snapshot) => {
                let getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                getData.sort((a, b) => {
                    return ((b.dateCreated < a.dateCreated) ? -1 : ((b.dateCreated > a.dateCreated) ? 1 : 0))
                })
                if (filter === true) {
                    getData = getData.filter(doc => doc.tracker && doc.tracker.totalPaymentFromHO > 0)
                    getData = getData.filter(doc => !doc.name.includes("Test") && !doc.name.includes("test"))
                }

                getData.forEach((doc) => {
                    const setLastEdit = (one, two, three) => {
                        const firstDate = new Date(Number(one), Number(two), Number(three))
                        const secondDate = new Date()
                        const diffDays = Math.round(Math.abs((firstDate - secondDate) / (24 * 60 * 60 * 1000))) - 1
                        let lastEdit = ""
                        if (diffDays <= 0) {
                            lastEdit = "Today"
                        } else if (diffDays === 1) {
                            lastEdit = diffDays + " day ago"
                        } else {
                            lastEdit = diffDays + " days ago"
                        }
                        return lastEdit
                    }
                    if (doc.lastEdit) {
                        doc.lastEdit = (doc.lastEdit).split("/")
                        doc.lastEdit = setLastEdit(doc.lastEdit[2], doc.lastEdit[1], doc.lastEdit[0])
                    }
                    if (doc.statusHistory && doc.statusHistory[0]) {
                        doc.lastEditSystem = (doc.statusHistory[doc.statusHistory.length - 1].date).split(" ")
                        doc.lastEditSystem[1] = (new Date(Date.parse(doc.lastEditSystem[1] + " 1, 2022")).getMonth()).toString() // Change month name to month index
                        doc.lastEditSystem = setLastEdit(doc.lastEditSystem[2], doc.lastEditSystem[1], doc.lastEditSystem[0])
                    }
                    if (!doc.followUpStatus) {
                        doc.followUpStatus = "Ongoing"
                    }
                })

                setProjectsAll(getData)
                setProjects(getData)
            })
    }
    const getProjectsDataForExcel = () => {
        firebase
            .firestore()
            .collection("projects")
            .where("dateCreated", ">", "2017-01-01 00:00:00")
            .onSnapshot((snapshot) => {
                let getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                getData.sort((a, b) => {
                    return ((b.dateCreated < a.dateCreated) ? -1 : ((b.dateCreated > a.dateCreated) ? 1 : 0))
                })
                if (filter === true) {
                    getData = getData.filter(doc => doc.tracker && doc.tracker.totalPaymentFromHO > 0)
                    getData = getData.filter(doc => !doc.name.includes("Test") && !doc.name.includes("test"))
                }

                setHeaders(Object.keys(getData[0]))
                getData.forEach((item, index) => {
                    for (var key in item) {
                        if (typeof item[key] === "object" || typeof item[key] === "boolean") {
                            getData[index][key] = JSON.stringify(getData[index][key]).split(`"`).join("")
                        }
                    }
                })
                setMainData(getData)
            })
    }
    const getTaxesData = (id) => {
        firebase
            .firestore()
            .collection("taxes")
            .where("projectId", "==", id)
            .get()
            .then((snapshot) => {
                let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                console.log(data)
                let temp = {}
                data.forEach(item => {
                    if (item.type === "PPh") {
                        temp.PPh = item.amount
                    } else if (item.type === "PPN") {
                        temp.PPN = item.amount
                    }
                })
                console.log(temp)
                setTaxes(temp)
            })
    }
    const getInvoicesData = (id) => {
        firebase
            .firestore()
            .collection("invoices")
            .where("projectId", "==", id)
            .get()
            .then((snapshot) => {
                let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                data.sort((a, b) => {
                    return a.forTermNumber - b.forTermNumber
                })
                setInvoices(data)
            })
    }
    const getDisbursementsData = (id) => {
        firebase
            .firestore()
            .collection("paymentrequests")
            .where("projectId", "==", id)
            .get()
            .then((snapshot) => {
                let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                data.sort((a, b) => {
                    return ((b.dateCreated > a.dateCreated) ? -1 : ((b.dateCreated < a.dateCreated) ? 1 : 0))
                })
                setDisbursements(data)
            })
    }
    const getFilesData = (id) => {
        firebase
            .firestore()
            .collection("files")
            .where("projectId", "==", id)
            .get()
            .then((snapshot) => {
                let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                setFiles(data)
            })
    }

    React.useEffect(() => {
        getProjectsData()
        getProjectsDataForExcel()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const searchProjectName = (e) => {
        if (e.target.value === "") {
            setProjects([])
            getProjectsData()
        } else {
            setProjects(projectsAll.filter(doc => doc.name.toLowerCase().includes((e.target.value).toLowerCase())))
            // getProjectsData("name", "==", (e.target.value).replace("\t", ""))
        }
    }
    const searchTransferPros = (e) => {
        if (e.target.value === "") {
            setProjects([])
            getProjectsData()
        } else {
            console.log(projectsAll[0])
            setProjects(projectsAll.filter(doc => doc.proEmail.toLowerCase().includes((e.target.value).toLowerCase())))
            // getProjectsData("proEmail", "==", (e.target.value).replace("\t", ""))
        }
    }

    const handleSubmit = (id) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const d = new Date()
        let date = `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`
        followUpData.push({ status: `${firstStatus} to ${secondStatus}`, notes, date })

        firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .update({ statusHistory: followUpData, lastEdit: `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`, followUpStatus: secondStatus })
            .then(() => {
                setFirstStatus(secondStatus)
                setSecondStatus(secondStatus)
                setFollowUpData(followUpData)
                setNotes("")
            })
    }
    const countProjects = () => {
        if (toggleCount === false) {
            projectsAll.forEach((doc) => {
                if (doc.followUpStatus) {
                    if (doc.followUpStatus === "Ongoing") { count[0].value += 1 }
                    if (doc.followUpStatus === "Idle-FU") { count[1].value += 1 }
                    if (doc.followUpStatus === "Completed-FU") { count[2].value += 1 }
                    if (doc.followUpStatus === "Completed") { count[3].value += 1 }
                    if (doc.followUpStatus === "Closed") { count[4].value += 1 }
                    if (doc.followUpStatus === "Deleted") { count[5].value += 1 }
                }
            })
            setToggleCount(true)
        }
        setShowModalCounter(true)
    }

    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })
    if (!data) return <Redirect to="/login" />

    return (
        <div>
            {projects ?
                <div style={{ minHeight: "90vh", marginLeft: isBrowser && 75 }}>
                    <h2 onClick={() => countProjects()} className="ButtonHover" style={{ marginLeft: 10, marginTop: 20, cursor: "pointer", display: "table" }}>Project Tracker</h2>
                    <br />
                    <div className="table-container">
                        <br />
                        <InputGroup className="InputGroup">
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none" onChange={searchProjectName} style={{ borderColor: "transparent" }} placeholder="Search by Project Name" />
                            </div>
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none" onChange={searchTransferPros} style={{ borderColor: "transparent" }} placeholder="Search by Professional Email" />
                            </div>
                        </InputGroup>
                        <br />
                        <div style={{ borderBottom: "1px solid lightgrey" }} />
                        <Table striped borderless responsive="xl" id="table-to-xls">
                            <thead style={{ position: isBrowser && "sticky", top: isBrowser && 63, background: "white" }}>
                                <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                    <th style={{ width: "3%" }} />
                                    <th style={{ width: "28%", minWidth: 200 }}><b>Name</b></th>
                                    <th style={{ width: "13%", minWidth: 100 }}><b>Value</b></th>
                                    <th style={{ width: "20%", minWidth: 200 }}><b>Completion</b></th>
                                    <th style={{ width: "13%", minWidth: 150 }}>
                                        <Dropdown style={{ width: "200px", padding: -5, margin: "-7px -12.2px -6.5px" }}>
                                            <Dropdown.Toggle variant="transparent" className="shadow-none ButtonHover">Status</Dropdown.Toggle>
                                            <Dropdown.Menu style={{ marginLeft: 10 }}>
                                                <Dropdown.Item className="removeBackground" onClick={() => getProjectsData()}>Show All</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Idle-FU"))}>Idle-FU</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Ongoing"))}>Ongoing</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Completed-FU"))}>Completed-FU</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Completed"))}>Completed</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Menunggu Review"))}>Menunggu Review</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Retention Period"))}>Masa Retensi</Dropdown.Item>
                                                <Dropdown.Item className="removeBackground" onClick={() => setProjects(projectsAll.filter(doc => doc.followUpStatus === "Closed"))}>Closed</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th style={{ width: "9%", minWidth: 100 }}><b>Last Edit</b></th>
                                    <th style={{ width: "11%", minWidth: 135 }}><b>Start Date</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.length !== 0 ? projects.map((item, index) => {
                                    return (
                                        <tr key={index} style={{ color: "#949495", background: projects.length === 1 && "white" }}>
                                            <td style={{ textAlign: "center" }}>
                                                <Button
                                                    onClick={() => { setShowModalInfo(true); getTaxesData(item.id); getInvoicesData(item.id); getFilesData(item.id); getDisbursementsData(item.id); setViewDetails(item) }}
                                                    className="shadow-none ButtonHover"
                                                    style={{ margin: -6, marginTop: -6, color: "#949495" }} size="sm" variant="transparent"
                                                >
                                                    <i className="fas fa-info-circle"></i>
                                                </Button>
                                            </td>
                                            <td>{item.name ? (isBrowser ? item.name : (item.name.length < 30 ? item.name : item.name.slice(0, 30) + "...")) : "-"}</td>
                                            <td>{item.tracker.totalProjectAmountAfterTax ? item.tracker.totalProjectAmountAfterTax.toLocaleString() : "-"}</td>
                                            <td>
                                                <div style={{ display: "flex", background: "transparent" }}>
                                                    <div style={{ background: "white", border: "1px solid #e1e1e1", width: "70%", height: "11px", borderRadius: "5px", marginTop: "5px" }}>
                                                        <div style={{ backgroundColor: "#27be78", width: item.progress ? item.progress + "%" : 0 + "%", height: "100%", borderRadius: "5px" }}></div>
                                                    </div>
                                                    <div style={{ marginLeft: "5px", textAlign: "left", width: 35 }}>{item.progress ? item.progress + "%" : "0%"}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    onClick={() => {
                                                        setShowModalStatus(true);
                                                        setViewDetails(item);
                                                        setFirstStatus(item.followUpStatus);
                                                        setFollowUpData(item.statusHistory ? item.statusHistory : [])
                                                    }}
                                                    style={{
                                                        color:
                                                            item.followUpStatus === "Completed" ? "green" :
                                                                item.followUpStatus === "Ongoing" ? "grey" :
                                                                    item.followUpStatus === "Closed" || item.followUpStatus === "Deleted" ? "white" :
                                                                        item.followUpStatus === "Completed-FU" || item.followUpStatus === "Idle-FU" ||
                                                                            item.followUpStatus === "No Reply 1" || item.followUpStatus === "No Reply 2" || item.followUpStatus === "No Reply 3" ? "black" :
                                                                            item.followUpStatus === "Menunggu Review" ? "black" : "grey"
                                                        ,
                                                        backgroundColor:
                                                            item.followUpStatus === "Completed" ? "#D9F2E2" :
                                                                item.followUpStatus === "Ongoing" ? "lightgrey" :
                                                                    item.followUpStatus === "Closed" || item.followUpStatus === "Deleted" ? "dimgray" :
                                                                        item.followUpStatus === "Completed-FU" || item.followUpStatus === "Idle-FU" ||
                                                                            item.followUpStatus === "No Reply 1" || item.followUpStatus === "No Reply 2" || item.followUpStatus === "No Reply 3" ? "salmon" :
                                                                            item.followUpStatus === "Menunggu Review" ? "gold" : "lightgrey"
                                                        ,
                                                        borderRadius: 50, textAlign: "center", width: "90%", cursor: "pointer"
                                                    }}
                                                >
                                                    {item.followUpStatus ? item.followUpStatus : "No Status"}
                                                </div>
                                            </td>
                                            <td>{item.lastEdit ? item.lastEdit : (item.lastEditSystem ? item.lastEditSystem : "-")}</td>
                                            <td>{item.dateCreated && item.dateCreated.slice(0, 16)}</td>
                                        </tr>
                                    )
                                })
                                    : <tr><td colSpan="7" style={{ textAlign: "center", color: "transparent" }}>-</td></tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                    <br />
                </div>
                :
                <i style={{ fontSize: 200 }} className="fas fa-spinner fa-spin"></i>
            }
            <Modal size="xl" scrollable={true} style={{ marginTop: 150 }} show={showModalInfo} onHide={() => setShowModalInfo(false)} >
                <Modal.Header>
                    <Modal.Title style={{ marginLeft: 10 }}>{viewDetails.name}</Modal.Title>
                    <a style={{ marginRight: 10 }} href={"https://www.arsitag.com/project/" + viewDetails.id + "/" + viewDetails.authToken} target="_blank" rel="noopener noreferrer">
                        Rating & Review
                    </a>
                </Modal.Header>
                <Modal.Body className="ModalBody">
                    <div>
                        <div>Project ID<br /><b>{viewDetails.id ? viewDetails.id : "-"}</b></div>
                        <div>Tier Number<br /><b>{viewDetails.tierNumber ? viewDetails.tierNumber : "-"}</b></div>
                        <div>Fee (%)<br /><b>{viewDetails.feePercentage ? viewDetails.feePercentage : "-"}</b></div>
                        <div>Approved SPK Date<br /><b>{viewDetails.dateSPKApproved ? viewDetails.dateSPKApproved : "-"}</b></div>
                        <div>Client Name<br /><b>{viewDetails.owner ? viewDetails.owner.name : "-"}</b></div>
                        <div>Client Phone<br /><b>{viewDetails.owner ? viewDetails.owner.phone : "-"}</b></div>
                        <div>Client Email<br /><b>{viewDetails.owner ? viewDetails.owner.email : "-"}</b></div>
                        <div>Deposit HO<br /><b>{viewDetails.tracker ? viewDetails.tracker.totalPaymentFromHO.toLocaleString() : "-"}</b></div>
                        <div>Professional Name<br /><b>{viewDetails.proName ? viewDetails.proName : "-"}</b></div>
                        <div>Professional Phone<br /><b>{viewDetails.proPhone ? viewDetails.proPhone : "-"}</b></div>
                        <div>Professional Email<br /><b>{viewDetails.proEmail ? viewDetails.proEmail : "-"}</b></div>
                        <div>Value Work Approved<br /><b>{viewDetails.tracker ? viewDetails.tracker.totalAmountOfApprovedWork.toLocaleString() : "-"}</b></div>
                        <div>Payment Disburse<br /><b>{viewDetails.tracker ? viewDetails.tracker.totalRequestedPaymentFromPro.toLocaleString() : "-"}</b></div>
                        <div>PPN (%)<br /><b>{taxes.PPN ? taxes.PPN : "-"}</b></div>
                        <div>PPh (%)<br /><b>{taxes.PPh ? taxes.PPh : "-"}</b></div>
                        <div>Retention (%)<br /><b>{viewDetails.maintenance ? viewDetails.maintenance.percentage ? viewDetails.maintenance.percentage : 0 : "-"}</b></div>
                        <div>Rating<br /><b>{viewDetails.rating ? viewDetails.rating : "-"}</b></div>
                        <div>Review<br /><b>{viewDetails.review ? viewDetails.review : "-"}</b></div>
                        <div>Type<br /><b>{viewDetails.type ? viewDetails.type : "-"}</b></div>
                        <div>Purpose<br /><b>{viewDetails.purpose ? viewDetails.purpose : "-"}</b></div>
                        <div>Location<br /><b>{viewDetails.location ? viewDetails.location : "-"}</b></div>
                        <div style={{ width: 1000 }}>RAB
                            <br />
                            <ul style={{ paddingLeft: isBrowser && 17 }}>
                                {files && files.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a style={{ marginRight: 10 }} href={item.downloadUrl} target="_blank" rel="noopener noreferrer">
                                                <b>{item.fileName}</b>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <Table striped borderless style={{ margin: 0 }}>
                        <thead>
                            <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                <th></th>
                                <th><b>Term</b></th>
                                <th><b>Invoice Number</b></th>
                                <th><b>Status</b></th>
                                <th><b>Amount</b></th>
                                <th><b>Payment Date</b></th>
                                <th><b>Remaining Amount</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices && invoices.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td></td>
                                        <td>{item.forTermNumber}</td>
                                        <td>{item.invoiceNumber}</td>
                                        <td>{item.status}</td>
                                        <td>{item.amountAfterTax.toLocaleString()}</td>
                                        <td>{item.paymentDate ? item.paymentDate : "-"}</td>
                                        <td>{item.remainingAmount ? item.remainingAmount.toLocaleString() : "-"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <br />
                    <Table striped borderless style={{ margin: 0 }}>
                        <thead>
                            <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                <th></th>
                                <th><b>Requested Amount</b></th>
                                <th><b>Arsitag Fee</b></th>
                                <th><b>Retention Amount</b></th>
                                <th><b>Transferred Amount</b></th>
                                <th><b>Payment Date</b></th>
                                <th><b>Date Created</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {disbursements.length > 0 ? disbursements.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td></td>
                                        <td>{item.requestedAmount.toLocaleString()}</td>
                                        <td>{item.arsitagFee.toLocaleString()}</td>
                                        <td>{item.retentionAmount.toLocaleString()}</td>
                                        <td>{item.transferredAmount.toLocaleString()}</td>
                                        <td>{item.dateUpdated}</td>
                                        <td>{item.dateCreated}</td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td colSpan={7} className="text-center">-</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => setShowModalInfo(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" style={{ marginTop: 150 }} show={showModalStatus} onHide={() => setShowModalStatus(false)}>
                <Modal.Header><Modal.Title>{viewDetails.name}</Modal.Title></Modal.Header>
                <Modal.Body style={{ minHeight: 250 }}>
                    <Table striped borderless style={{ margin: "-15px -15px -16px", width: 798, borderBottom: "1px solid #F1F1F1" }}>
                        <thead>
                            <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                <th style={{ width: "2%" }}></th>
                                <th style={{ width: "30%" }}><b>Status</b></th>
                                <th style={{ width: "35%" }}><b>Notes</b></th>
                                <th style={{ width: "20%" }}><b>Date</b></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <Dropdown style={{ width: "100px" }}>
                                        <Dropdown.Toggle variant="transparent" className="shadow-none" style={{ background: "white", borderColor: "lightgrey" }}>{secondStatus ? secondStatus : viewDetails.followUpStatus}</Dropdown.Toggle>
                                        <Dropdown.Menu className="removeHighlight">
                                            <Dropdown.Item onClick={() => setSecondStatus("Idle-FU")}>Idle-FU</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("Ongoing")}>Ongoing</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("Completed-FU")}>Completed-FU</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("Completed")}>Completed</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("Closed")}>Closed</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("Deleted")}>Deleted</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("No Reply 1")}>No Reply 1</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("No Reply 2")}>No Reply 2</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setSecondStatus("No Reply 3")}>No Reply 3</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>
                                    <textarea cols="30" value={notes} onChange={e => setNotes(e.target.value)} style={{ resize: "vertical", borderColor: "lightgrey", borderRadius: 3, marginTop: 6 }}></textarea>
                                </td>
                                <td>-</td>
                                <td>
                                    {(() => {
                                        if ((!secondStatus) || (firstStatus === secondStatus)) { // || (firstStatus === "Ongoing" && secondStatus === "Idle-FU") || (firstStatus === "Completed-FU" && (secondStatus === "Ongoing" || secondStatus === "Idle-FU")) || (firstStatus === "Completed") || (firstStatus === "Closed")
                                            return <Button className="ButtonRed" style={{ marginRight: 9 }} disabled>Submit</Button>
                                        } else {
                                            return <Button className="ButtonRed" style={{ marginRight: 9 }} onClick={() => handleSubmit(viewDetails.id)}>Submit</Button>
                                        }
                                    })()}
                                </td>
                            </tr>
                            {followUpData.length !== 0 && followUpData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td></td>
                                        <td>{item.status}</td>
                                        <td>{item.notes}</td>
                                        <td>{item.date}</td>
                                        <td></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => { setShowModalStatus(false); setSecondStatus(null) }}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" scrollable={true} style={{ marginTop: 150 }} show={showModalCounter} onHide={() => setShowModalCounter(false)}>
                <Modal.Header><Modal.Title onClick={() => { setFilter(!filter); getProjectsData() }}>Counter</Modal.Title></Modal.Header>
                <Modal.Body className="ModalBody">
                    <div style={{ height: 250 }}>
                        <ResponsiveContainer>
                            <BarChart data={count} margin={{ top: 20 }}>
                                <Bar dataKey="value" label={{ position: "top" }}>
                                    {count.map((index) => (
                                        <Cell key={`cell-${index}`} fill="salmon" />
                                    ))}
                                </Bar>
                                <XAxis dataKey="name" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ExcelFile element={<Button style={{ width: "160px" }} className="shadow-none ButtonGrey ButtonMargin">Export (.xlsx)</Button>} filename="projects">
                        <ExcelSheet data={mainData} name="Sheet1">
                            {headers.map((item, index) => {
                                return (
                                    <ExcelColumn key={index} label={item} value={item} />
                                )
                            })}
                        </ExcelSheet>
                    </ExcelFile>
                    <Button className="ButtonGrey" onClick={() => setShowModalCounter(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}