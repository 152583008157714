import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// PRODUCTION
const firebaseConfig = {
    apiKey: 'AIzaSyBcVnqhYyjCsjPL7h5MHnuqri_UVj-x3l8',
    authDomain: 'arsitag-arsipay.firebaseapp.com',
    databaseURL: 'https://arsitag-arsipay.firebaseio.com',
    projectId: 'arsitag-arsipay',
    storageBucket: 'arsitag-arsipay.appspot.com',
    messagingSenderId: '830740171184',
    appId: '1:830740171184:android:822e697a54139992'
}

// STAGING
// const firebaseConfig = {
//     apiKey: "AIzaSyAFftaKGqooU_50oC9s22DZsRgnaqSKX4k",
//     authDomain: "arsitag-staging.firebaseapp.com",
//     databaseURL: "https://arsitag-staging.firebaseio.com",
//     projectId: "arsitag-staging",
//     storageBucket: "arsitag-staging.appspot.com",
//     messagingSenderId: "342111732936",
//     appId: "1:342111732936:web:29fdbdb3ecb63725fc91fd"
// };

firebase.initializeApp(firebaseConfig)

// export const auth = firebase.auth()
// export const db = firebase.firestore()
export default firebase