import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'

export default function Home() {
    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })
    if (data) return <Redirect to="disbursement" />
    if (!data) return <Redirect to="/login" />
}
