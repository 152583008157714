import Axios from "axios"

export const login = (data, action) => {
    return async (dispatch) => {
        try {
            const res = await Axios.post(`https://www.arsitag.com/api/v1/login`, data,
            {
                "Referer": "ionic.arsitag.com",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            })

            if (res.data.message.includes("Login failed! Please input the right email & password.")) {
                return action("Invalid email and/or password.")
            } else if (res.data.data === null) {
                return action(res.data.message)
            } else if (res.data.data.login_super_user === false) {
                return action("Login failed! This account is not authorized.")
            }
            localStorage.setItem("user", JSON.stringify(res.data))
            dispatch({ type: "LOGIN", payload: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
}

export const logout = () => {
    return async (dispatch) => {
        try {
            localStorage.clear()
            dispatch({ type: "LOGOUT" })
        }
        catch (err) {
            console.log(err)
        }
    }
}

export const keepLogin = () => {
    return async (dispatch) => {
        try {
            const data = JSON.parse(localStorage.getItem("user"))
            dispatch({ type: "LOGIN", payload: data })
        }
        catch (err) {
            console.log(err)
        }
    }
}