import React from "react"
import { Button } from "react-bootstrap"
import firebase from "../firebase"

// import { CSVLink } from "react-csv"

import ReactExport from "react-data-export"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default function Test() {
    const [mainData, setMainData] = React.useState([])
    const [headers, setHeaders] = React.useState([])
    const [collection, setCollection] = React.useState(false)
    const [isDone, setIsDone] = React.useState(false)

    const selectCollection = (selectedCollection) => {
        setIsDone(false)
        setCollection(selectedCollection)
        firebase
            .firestore()
            .collection(selectedCollection)
            // .limit(5)
            .onSnapshot((snapshot) => {
                let getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                getData.sort((a, b) => {
                    return ((b.dateCreated < a.dateCreated) ? -1 : ((b.dateCreated > a.dateCreated) ? 1 : 0))
                })

                setHeaders(Object.keys(getData[0]))
                getData.forEach((item, index) => {
                    for (var key in item) {
                        if (typeof item[key] === "object" || typeof item[key] === "boolean") {
                            getData[index][key] = JSON.stringify(getData[index][key]).split(`"`).join("")
                        }
                    }
                })

                // console.log(getData)
                setMainData(getData)
                setIsDone(true)
            })
    }

    React.useEffect(() => {
        selectCollection("projects")
    }, [])

    return (
        <div className="d-flex flex-column align-items-center text-center" style={{ gap: 50 }}>
            <h1>{collection ? collection.toUpperCase() : "-"}</h1>
            <div style={{ display: "flex", gap: 5 }}>
                <Button style={{ boxShadow: "none", background: collection === "projects" && "maroon" }} onClick={() => selectCollection("projects")}>Projects</Button>
                <Button style={{ boxShadow: "none", background: collection === "users" && "maroon" }} onClick={() => selectCollection("users")}>Users</Button>
                <Button style={{ boxShadow: "none", background: collection === "paymentrequests" && "maroon" }} onClick={() => selectCollection("paymentrequests")}>Payment Requests</Button>
            </div>
            {/* {isDone ?
                <CSVLink filename={`${collection}`} data={mainData} separator={";"}><h3>Export</h3></CSVLink>
                :
                <h3>Export</h3>
            } */}
            {isDone ?
                <ExcelFile filename={collection}>
                    <ExcelSheet data={mainData} name={collection}>
                        {headers.map((item, index) => {
                            return (
                                <ExcelColumn key={index} label={item} value={item} />
                            )
                        })}
                    </ExcelSheet>
                </ExcelFile>
                :
                <button disabled>Download</button>
            }
        </div>
    )
}