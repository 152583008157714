import React from "react"
import firebase from "../firebase"
import { Table, Button, Modal, Dropdown, Form, InputGroup } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { CSVLink } from "react-csv"
import { isBrowser } from "react-device-detect"
import Axios from "axios"

export default function Invoice() {
    const [invoices, setInvoices] = React.useState(null)
    const [invoicesAll, setInvoicesAll] = React.useState([])
    const [lengthLimit, setLengthLimit] = React.useState(20)
    const [toggleShowMoreButton, setToggleShowMoreButton] = React.useState(false)
    const [toggleShowAllButton, setToggleShowAllButton] = React.useState(false)
    const [viewDetails, setViewDetails] = React.useState({})
    const [showModalDetails, setShowModalDetails] = React.useState(false)
    const [showModalInfo, setShowModalInfo] = React.useState(false)
    const [transferStatus, setTransferStatus] = React.useState("Menunggu Pembayaran")
    const [image, setImage] = React.useState(null)
    const [notes, setNotes] = React.useState("")

    const getInvoicesData = (limit) => {
        firebase
            .firestore()
            .collection("invoices")
            .limit(limit)
            .orderBy("dateCreated", "desc")
            .onSnapshot((snapshot) => {
                const getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                setInvoices(getData)
            })
    }
    const getInvoicesDataAll = () => {
        firebase.firestore().collection("invoices").orderBy("dateCreated", "desc")
            .onSnapshot((snapshot) => {
                const getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                setInvoicesAll(getData)
            })
    }

    React.useEffect(() => {
        getInvoicesData(10)
        getInvoicesDataAll()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleShowMore = () => {
        setLengthLimit(lengthLimit + 10)
        getInvoicesData(lengthLimit)
    }
    const handleShowAll = () => {
        setToggleShowAllButton(true)
        getInvoicesData()
        setTimeout(function () { setToggleShowMoreButton(true) }, 5000)
    }

    const searchData = (data, operator, target) => {
        firebase
            .firestore()
            .collection("invoices")
            .where(data, operator, target)
            .get()
            .then((snapshot) => {
                let getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                getData.sort((a, b) => {
                    return ((b.dateCreated < a.dateCreated) ? -1 : ((b.dateCreated > a.dateCreated) ? 1 : 0))
                })
                setInvoices(getData)
                setToggleShowMoreButton(true)
            })
    }
    const searchProjectName = (e) => {
        if (e.target.value === "") {
            setInvoices([])
            getInvoicesData(10)
            setToggleShowAllButton(false)
            setToggleShowMoreButton(false)
        } else {
            searchData("timestampName", "==", (e.target.value).replace("\t", ""))
        }
    }
    const searchUniqueAmount = (e) => {
        if (e.target.value === "") {
            setInvoices([])
            getInvoicesData(10)
            setToggleShowAllButton(false)
            setToggleShowMoreButton(false)
        } else {
            searchData("uniqueAmount", "==", parseFloat(e.target.value.replace(/,/g, "")))
        }
    }

    const handleChangeFile = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    }
    const handleChangeNotes = (e) => {
        setNotes(e.target.value)
    }
    const handleSubmit = (id, status, grossAmount, invoiceNumber) => {
        const update = (url) => {
            Axios.post("https://us-central1-arsitag-arsipay.cloudfunctions.net/dashboard/invoicepaid",
                {
                    invoiceNumber,
                    grossAmount,
                    receiptFile: url,
                    notes,
                    status
                }, {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            })
                .then(() => {
                    setTransferStatus("Menunggu Pembayaran")
                    setImage(null)
                    setNotes("")
                })
        }

        if (image === null) {
            setShowModalDetails(false)
            update("")
        } else if (image !== null) {
            setShowModalDetails(false)
            firebase
                .storage()
                .ref(`invoices/${id}/${image.name}`)
                .put(image)
                .then(() => {
                    firebase
                        .storage()
                        .ref(`invoices/${id}`)
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            update(url)
                        })
                })
        }
    }

    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })
    if (!data) return <Redirect to="/login" />
    if (data.role_id === 7) return <Redirect to="/project" />

    return (
        <div>
            {invoices ?
                <div style={{ minHeight: "90vh", marginLeft: isBrowser && 75 }}>
                    <h2 className="ButtonHover" style={{ marginLeft: 10, marginTop: 20, cursor: "pointer", display: "table" }}>Invoice Tracker</h2>
                    <br />
                    <div className="table-container">
                        <br />
                        <InputGroup className="InputGroup">
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none" onChange={searchProjectName} style={{ borderColor: "transparent" }} placeholder="Search by Project Name" />
                            </div>
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none" onChange={searchUniqueAmount} style={{ borderColor: "transparent" }} placeholder="Search by Unique Amount" />
                            </div>
                        </InputGroup>
                        <br />
                        <div style={{ borderBottom: "1px solid lightgrey" }} />
                        <Table className="asd" striped borderless responsive="xl" id="table-to-xls">
                            <thead style={{ position: isBrowser && "sticky", top: isBrowser && 65, background: "white" }}>
                                <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                    <th style={{ width: "3%" }} />
                                    <th style={{ width: "8%" }}><b>Date</b></th>
                                    <th style={{ width: "8%" }}><b>Due Date</b></th>
                                    <th style={{ width: "15%" }}><b>Invoice Number</b></th>
                                    <th style={{ width: "23%" }}><b>Project Name</b></th>
                                    <th style={{ width: "10%" }}><b>Base Amount</b></th>
                                    <th style={{ width: "11%" }}><b>Unique Amount</b></th>
                                    <th style={{ width: "16%" }}><b>Status Invoice</b></th>
                                    <th style={{ width: "3%" }} />
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.length !== 0 ? invoices.map((item, index) => {
                                    return (
                                        <tr key={index} style={{ color: "#949495" }}>
                                            <td style={{ textAlign: "center" }}>
                                                <Button className="shadow-none ButtonHover" onClick={() => { setShowModalInfo(true); setViewDetails(item) }} style={{ margin: -6, marginTop: -6, color: "#949495" }} size="sm" variant="transparent" >
                                                    <i className="fas fa-info-circle"></i>
                                                </Button>
                                            </td>
                                            <td>{item.dateCreated ? item.dateCreated.slice(0, 10) : "-"}</td>
                                            <td>{item.paymentDueDate ? item.paymentDueDate.slice(0, 10) : "-"}</td>
                                            <td>{item.invoiceNumber}</td>
                                            <td>{item.timestampName ? item.timestampName : "-"}</td>
                                            <td>{item.finalAmount ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(item.finalAmount - (item.pphAmount ? item.pphAmount : 0))) : "-"}</td>
                                            <td>{item.uniqueAmount ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(item.uniqueAmount)) : "-"}</td>
                                            <td>
                                                {(() => {
                                                    if (item.status === "Sudah Terbayar") {
                                                        return <div style={{ color: "green", backgroundColor: "#D9F2E2", borderRadius: 50, textAlign: "center" }}>{item.status}</div>
                                                    } else if (item.status === "Menunggu Pembayaran") {
                                                        return <div style={{ color: "grey", backgroundColor: "#FFF6AB", borderRadius: 50, textAlign: "center" }}>{item.status}</div>
                                                    } else if (item.status === "Belum Aktif" || item.status === "Ditolak") {
                                                        return <div style={{ color: "grey", backgroundColor: "lightgrey", borderRadius: 50, textAlign: "center" }}>{item.status}</div>
                                                    }
                                                })()}
                                            </td>
                                            {item.status === "Menunggu Pembayaran" || item.status === "Belum Aktif" || item.status === "Ditolak" ?
                                                <td><Button className="shadow-none ButtonHover" style={{ margin: -6 }} size="sm" variant="transparent" onClick={() => { setShowModalDetails(true); setViewDetails(item) }}>...</Button></td>
                                                :
                                                <td></td>
                                            }
                                        </tr>
                                    )
                                })
                                    :
                                    <tr><td colSpan="10" style={{ textAlign: "center", color: "transparent" }}>-</td></tr>
                                }
                            </tbody>
                        </Table>
                        {(() => {
                            if (!toggleShowMoreButton && invoices.length !== 0) {
                                return (
                                    <div className="div-buttons">
                                        <Button onClick={handleShowMore} className="shadow-none ButtonGrey ButtonMargin">Show More</Button>
                                        {toggleShowAllButton ?
                                            <Button onClick={handleShowAll} className="shadow-none ButtonGrey ButtonMargin"><i className="fas fa-spinner fa-spin"></i></Button>
                                            :
                                            <Button onClick={handleShowAll} className="shadow-none ButtonGrey ButtonMargin">Show All</Button>
                                        }
                                        <CSVLink style={{ textDecoration: "none", color: "#212529" }} filename={"invoices.xls"} data={invoicesAll} separator={";"}>
                                            <Button style={{ width: "160px" }} className="shadow-none ButtonGrey ButtonMargin">Export Data (.xls)</Button>
                                        </CSVLink>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
                :
                <i style={{ fontSize: 200 }} className="fas fa-spinner fa-spin"></i>
            }
            <Modal size="lg" scrollable={true} style={{ marginTop: 150 }} show={showModalDetails} onHide={() => setShowModalDetails(false)} >
                <Modal.Header><Modal.Title>Confirmation Transfer Pros</Modal.Title></Modal.Header>
                <Modal.Body style={{ minHeight: 200 }}>
                    <Table striped borderless style={{ margin: "-15px -15px -16px", width: 798, borderBottom: "1px solid #F1F1F1" }}>
                        <thead style={{ position: "sticky", top: "-16px" }}>
                            <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                <th style={{ width: "1%" }}></th>
                                <th style={{ width: "32%" }}><b>Upload Payment Proof</b></th>
                                <th style={{ width: "35%" }}><b>Status Transfer</b></th>
                                <th style={{ width: "32%" }}><b>Notes</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <Form.Group controlId="formFile" className="mb-3" style={{ width: 100 }}>
                                        <Form.Control style={{ cursor: "pointer" }} type="file" onChange={handleChangeFile} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Dropdown style={{ width: "100px" }}>
                                        <Dropdown.Toggle variant="transparent" className="shadow-none" style={{ background: "white", borderColor: "lightgrey" }}>{transferStatus}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setTransferStatus("Menunggu Pembayaran")}>Menunggu Pembayaran</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setTransferStatus("Sudah Terbayar")}>Sudah Terbayar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>
                                    <textarea cols="20" value={notes} onBlur={handleChangeNotes} style={{ resize: "vertical", borderColor: "lightgrey", borderRadius: 3, marginTop: 6 }}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => { setShowModalDetails(false); setImage(null); setNotes(""); setTransferStatus("Menunggu Pembayaran") }}>Cancel</Button>
                    <Button className="ButtonRed" onClick={() => handleSubmit(viewDetails.id, transferStatus, viewDetails.amountAfterTax, viewDetails.invoiceNumber)}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" scrollable={true} style={{ marginTop: 150 }} show={showModalInfo} onHide={() => setShowModalInfo(false)} >
                <Modal.Header><Modal.Title>{viewDetails.projectName}</Modal.Title></Modal.Header>
                <Modal.Body className="ModalBody">
                    <div>
                        <div>HO Email<br /><b>{viewDetails.hoEmail ? viewDetails.hoEmail : "-"}</b></div>
                        <div>VA Number<br /><b>{viewDetails.vaNumber ? viewDetails.vaNumber : "-"}</b></div>
                        <div>Retention<br /><b>{viewDetails.retentionAmount ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(viewDetails.retentionAmount)) : 0}</b></div>
                        <div>Fee Arsitag<br /><b>{viewDetails.arsitagFee ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(viewDetails.arsitagFee)) : "-"}</b></div>
                        <div>PPh Amount<br /><b>{viewDetails.pphAmount ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(viewDetails.pphAmount)) : 0}</b></div>
                        <div>PPN Amount<br /><b>{viewDetails.taxAmount ? Intl.NumberFormat("en-ES", { currency: "USD", style: "decimal" }).format(String(viewDetails.taxAmount)) : 0}</b></div>
                        <div>Notes<br /><b>{viewDetails.notes ? viewDetails.notes : "-"}</b></div>
                        <div>Receipt<br />
                            {viewDetails.downloadUrl ?
                                <a href={viewDetails.downloadUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#1E7798" }} download>Download</a>
                            :    
                                <b>-</b>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => setShowModalInfo(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}