import React from "react"
import Axios from "axios"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Button } from "react-bootstrap"
import { ResponsiveContainer, Line, XAxis, Tooltip, YAxis, ComposedChart, Legend } from "recharts"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ReactExport from "react-data-export"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

export default function DashboardMarketplace() {
    const [ongoingProject, setOngoingProject] = React.useState(null)
    const [mainData, setMainData] = React.useState([
        {
            title: "Monthly Project",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Monthly Project" }
            ],
            data: [] 
        },
        {
            title: "Average Value",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Monthly Project" }
            ],
            data: [] 
        },
        {
            title: "Average Fee",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Average Fee" }
            ],
            data: [] 
        }
    ])
    const [dateRange, setDateRange] = React.useState([null, null])
    const [startDate, endDate] = dateRange

    const getData = (date, getWhich) => {
        let tempArr = [null, null, null]
        Axios.post("https://www.arsitag.com/api/v1/dashboard-project/project-monthly", date).then(res => {
            tempArr[0] = res.data.data
            Axios.post("https://www.arsitag.com/api/v1/dashboard-project/project-average-value", date).then(res => {
                tempArr[1] = res.data.data
                Axios.post("https://www.arsitag.com/api/v1/dashboard-project/project-average-fee", date).then(res => {
                    tempArr[2] = res.data.data
                    
                    let newArr = [...mainData]
                    newArr.forEach((item) => {
                        item.data = []
                    })
                    if (getWhich === "getAll" || getWhich === "getData1") {
                        newArr[0].chart = tempArr[0]
                        tempArr[0].forEach((item) => {
                            newArr[0].data.push([
                                { value: item.year },
                                { value: item.month_name },
                                { value: item.total_project }
                            ])
                        })
                    }
                    if (getWhich === "getAll" || getWhich === "getData2") {
                        newArr[1].chart = tempArr[1]
                        tempArr[1].forEach((item) => {
                            newArr[1].data.push([
                                { value: item.year },
                                { value: item.month_name },
                                { value: item.average_value }
                            ])
                        })
                    }
                    if (getWhich === "getAll" || getWhich === "getData3") {
                        newArr[2].chart = tempArr[2]
                        tempArr[2].forEach((item) => {
                            item.average_fee_percentage = String(item.average_fee) + "%"
                            newArr[2].data.push([
                                { value: item.year },
                                { value: item.month_name },
                                { value: item.average_fee_percentage }
                            ])
                        })
                    }

                    newArr.forEach((item) => {
                        item.chart.forEach((item2) => {
                            item2.month_name_short = item2.month_name.substring(0, 3)
                        })
                    })
                    setMainData(newArr)
                })
            })
        })
    }

    React.useEffect(() => {
        const today = new Date()
        const dateTo = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)
        today.setMonth(today.getMonth() - 7)
        const dateFrom = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)
        const date = dateFrom + "&" + dateTo

        Axios.post("https://www.arsitag.com/api/v1/dashboard-project/project-ongoing").then(res => {
            setOngoingProject(res.data.data[0].total_project)
        })
        getData(date, "getAll")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDateRange = (inputDate, getWhich) => {
        setDateRange(inputDate)

        if (inputDate[1] !== null) {
            const dateArr = []
            for (let i = 0; i <= 1; i++) {
                const offset = inputDate[i].getTimezoneOffset()
                inputDate[i] = new Date(inputDate[i].getTime() - (offset * 60 * 1000))
                dateArr.push(inputDate[i].toISOString().split("T")[0])
            }
            if (dateArr.length === 2) {
                const tempYear = dateArr[1].slice(0, 4)
                const tempMonth = dateArr[1].slice(5, 7)[0] === "0" ? dateArr[1].slice(6, 7) : dateArr[1].slice(5, 7)
                dateArr[1] = new Date(tempYear, tempMonth, 0)
                const offset = dateArr[1].getTimezoneOffset()
                dateArr[1] = new Date(dateArr[1].getTime() - (offset * 60 * 1000))
                dateArr[1] = dateArr[1].toISOString().split("T")[0]
                const date = dateArr[0] + "&" + dateArr[1]
                getData(date, getWhich)
                setDateRange([null, null])
            }
        }
    }

    const capitalizeLetter = (input) => {
        return input.split("_").map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ")
    }

    const { userLoggedIn } = useSelector((state) => {
        return {
            userLoggedIn: state.user.data
        }
    })
    if (!userLoggedIn) return <Redirect to="/login" />

    return (
        <div>
            <div className="boxShadow" style={{ height: "80vh", width: "83vw", padding: "35px 40px", borderRadius: 10, background: "white", overflow: "scroll" }}>
                <h2>Project</h2>
                <hr style={{ margin: "35px -40px", color: "lightgrey", padding: 1 }} />
                <div className="d-flex justify-content-center">
                    <div className="d-flex justify-content-left" style={{ background: "transparent" }}>
                        <div className="boxShadow" style={{ background: "white", width: 200, padding: "10px 15px", borderRadius: 3, marginRight: 35 }}>
                            <div><b>Ongoing Project</b></div>
                            <div>{ongoingProject ? ongoingProject : "-"}</div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
                {mainData.map((item, index) => {
                    return (
                        <div key={index} >
                            <div className="d-flex flex-column align-items-center" style={{ height: "50vh", width: "100%" }}>
                                <br /><h4>{item.title}</h4>
                                <div className="d-flex justify-content-between align-items-center" style={{ width: "85%" }}>
                                    <DatePicker
                                        className="boxShadow"
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => handleDateRange(update, "getData"+(index+1))}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                        placeholderText="Select start and end date"
                                        minDate={new Date("01-01-2022")} maxDate={new Date()}
                                    />
                                    <ExcelFile element={<Button style={{ width: "160px" }} className="shadow-none ButtonRed">Export (.xlsx)</Button>} filename={item.title}>
                                        <ExcelSheet dataSet={[item]} name="Sheet1" />
                                    </ExcelFile>
                                </div>
                                <ResponsiveContainer width="100%" height="75%">
                                    <ComposedChart margin={{ top: 20, right: 75, bottom: 0, left: 45 }} data={item.chart}>
                                        <XAxis dataKey="month_name_short" />
                                        <YAxis tickFormatter={tick => { return typeof tick === "number" && tick.toLocaleString() + (index === 2 ? "%" : "") }} domain={index === 1 ? [0, 400000000] : [0, "auto"]} />
                                        <Tooltip separator=": " formatter={(value, name) => [Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 }) + (index === 2 ? "%" : ""), capitalizeLetter(name)]} />
                                        <Legend verticalAlign="top" align="right" formatter={(value) => capitalizeLetter(value)} />
                                        {
                                            index === 0 ? <Line type="monotone" dataKey="total_project" stroke="purple" strokeWidth={2} /> :
                                            index === 1 ? <Line type="monotone" dataKey="average_value" stroke="purple" strokeWidth={2} /> :
                                            index === 2 ? <Line type="monotone" dataKey="average_fee" stroke="purple" strokeWidth={2} /> : null
                                        }
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                            {index !== 2 ?
                                <>
                                    <br /><br /><br /><br /><br /><br /><br />
                                </>
                                :
                                <>
                                    <br/><br/>
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}