import React from "react"
import { Container } from 'react-bootstrap'
import { Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { keepLogin } from './actions'
import './App.css'

import Login from "./components/Login"
import Navigation from './components/Navigation'
import DashboardGeneral from './pages/DashboardGeneral'
import DashboardMarketplace from './pages/DashboardMarketplace'
import DashboardProject from './pages/DashboardProject'
import Home from "./pages/Home"
import Disbursement from "./pages/Disbursement"
import Invoice from "./pages/Invoice"
import Project from "./pages/Project"
import Test from "./pages/Test"
import NotFound from "./pages/NotFound"

function App() {
  const user = JSON.parse(localStorage.getItem('user'))
  const dispatch = useDispatch()
  if (user) dispatch(keepLogin())

  const container = {
    display: "flex", justifyContent: "center", alignItems: "center",
    backgroundColor: "#FAF9F9",
    minHeight: "92%",
    maxWidth: "100%",
    margin: "0px", padding: "0px", border: "0px",
  }

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Navigation />
      <Container style={container}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard-general" component={DashboardGeneral} />
          <Route path="/dashboard-marketplace" component={DashboardMarketplace} />
          <Route path="/dashboard-project" component={DashboardProject} />
          <Route path="/disbursement" component={Disbursement} />
          <Route path="/invoice" component={Invoice} />
          <Route path="/project" component={Project} />
          <Route path="/test" component={Test} />
          <Route path='*' component={NotFound} />
        </Switch>
      </Container>
    </div>
  )
}

export default App