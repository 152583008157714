import React from "react"
import firebase from "../firebase"
import { Table, Button, Modal, Dropdown, Form, InputGroup } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { CSVLink } from "react-csv"
import { isBrowser } from "react-device-detect"
import Axios from "axios"

export default function Disbursement() {
    const [paymentRequests, setPaymentRequests] = React.useState(null)
    const [paymentRequestsAll, setPaymentRequestsAll] = React.useState([])
    const [lengthLimit, setLengthLimit] = React.useState(20)
    const [toggleShowMoreButton, setToggleShowMoreButton] = React.useState(false)
    const [toggleShowAllButton, setToggleShowAllButton] = React.useState(false)
    const [viewDetails, setViewDetails] = React.useState({})
    const [showModalDetails, setShowModalDetails] = React.useState(false)
    const [showModalInfo, setShowModalInfo] = React.useState(false)
    const [transferStatus, setTransferStatus] = React.useState("Pending")
    const [image, setImage] = React.useState(null)
    const [notes, setNotes] = React.useState("")

    const getDisbursementsData = (limit) => {
        firebase
            .firestore()
            .collection("paymentrequests")
            .limit(limit)
            .orderBy("dateUpdated", "desc")
            .get()
            .then((snapshot) => {
                const getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                setPaymentRequests(getData)
            })
    }
    const getDisbursementsDataAll = () => {
        firebase.firestore().collection("paymentrequests").orderBy("dateUpdated", "desc")
            .onSnapshot((snapshot) => {
                const getData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                setPaymentRequestsAll(getData)
            })
    }

    React.useEffect(() => {
        getDisbursementsData(10)
        getDisbursementsDataAll()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleShowMore = () => {
        setLengthLimit(lengthLimit + 10)
        getDisbursementsData(lengthLimit)
    }
    const handleShowAll = () => {
        setToggleShowAllButton(true)
        getDisbursementsData()
        setTimeout(function () { setToggleShowMoreButton(true) }, 5000)
    }

    const searchData = (data, operator, target) => {
        firebase
            .firestore()
            .collection("paymentrequests")
            .where(data, operator, target)
            .get()
            .then((snapshot) => {
                const getData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setPaymentRequests(getData)
                setToggleShowMoreButton(true)
            })
    }
    const searchProjectName = (e) => {
        if (e.target.value === "") {
            setPaymentRequests([])
            getDisbursementsData(10)
            setToggleShowAllButton(false)
            setToggleShowMoreButton(false)
        } else {
            searchData("timestampName", "==", (e.target.value).replace("\t", ""))
        }
    }
    const searchTransferPros = (e) => {
        if (e.target.value === "") {
            setPaymentRequests([])
            getDisbursementsData(10)
            setToggleShowAllButton(false)
            setToggleShowMoreButton(false)
        } else {
            searchData("totalAmount", "==", parseFloat(e.target.value.replace(/,/g, "")))
        }
    }

    const handleChangeFile = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    }
    const handleChangeNotes = (e) => {
        setNotes(e.target.value)
    }
    const handleSubmit = (id, status, projectId, paymentRequestId) => {
        const update = (url) => {
            Axios.post("https://us-central1-arsitag-arsipay.cloudfunctions.net/dashboard/paymentrequestpaid",
                {
                    status,
                    receiptFile: url,
                    notes,
                    projectId,
                    paymentRequestId
                }, {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            })
                .then(() => {
                    setTransferStatus("Pending")
                    setImage(null)
                    setNotes("")
                })
        }

        if (image === null) {
            setShowModalDetails(false)
            update("")
        } else if (image !== null) {
            setShowModalDetails(false)
            firebase
                .storage()
                .ref(`projects/${id}/paymentrequests/${projectId}/${image.name}`)
                .put(image)
                .then(() => {
                    firebase
                        .storage()
                        .ref(`projects/${id}/paymentrequests/${projectId}`)
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            update(url)
                        })
                })
        }
    }

    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })
    if (!data) return <Redirect to="/login" />
    if (data.role_id === 7) return <Redirect to="/project" />

    return (
        <div>
            {paymentRequests ?
                <div style={{ minHeight: "90vh", marginLeft: isBrowser && 75 }}>
                    <h2 style={{ marginLeft: 10, marginTop: 20 }}>Disbursement Tracker</h2>
                    <br />
                    <div className="table-container">
                        <br />
                        <InputGroup className="InputGroup">
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none" onChange={searchProjectName} style={{ borderColor: "transparent" }} placeholder="Search by Project Name" />
                            </div>
                            <div>
                                <InputGroup.Text className="InputGroupText"><i className="fas fa-search" /></InputGroup.Text>
                                <Form.Control className="shadow-none search" onChange={searchTransferPros} style={{ borderColor: "transparent" }} placeholder="Search by Transfer Pros" />
                            </div>
                        </InputGroup>
                        <br />
                        <div style={{ borderBottom: "1px solid lightgrey" }} />
                        <Table striped borderless responsive="xl">
                            <thead style={{ position: isBrowser && "sticky", top: isBrowser && 65, background: "white" }}>
                                <tr style={{ boxShadow: "inset 0 -1px 0 lightgrey" }}>
                                    <th style={{ width: "3%", minWidth: 39 }} />
                                    {/* <th style={{ width: "11%" }}><b>Date</b></th> */}
                                    {/* <th style={{ width: "17%" }}><b>Project ID</b></th> */}
                                    <th style={{ width: "22%" }}><b>Project Name</b></th>
                                    <th style={{ width: "9%" }}><b>Cashflow Out</b></th>
                                    <th style={{ width: "10%" }}><b>Bank Name</b></th>
                                    <th style={{ width: "15%" }}><b>Account Name</b></th>
                                    <th style={{ width: "11%" }}><b>Account Number</b></th>
                                    <th style={{ width: "9%" }}><b>Transfer Pros</b></th>
                                    <th style={{ width: "8%" }}><b>Date</b></th>
                                    <th style={{ width: "10%" }}><b>Status Transfer</b></th>
                                    <th style={{ width: "3%", minWidth: 39 }} />
                                </tr>
                            </thead>
                            <tbody>
                                {paymentRequests.length !== 0 ? paymentRequests.map((item, index) => {
                                    return (
                                        <tr key={index} style={{ color: "#949495" }}>
                                            <td style={{ textAlign: "center" }}>
                                                <Button className="shadow-none ButtonHover" onClick={() => { setShowModalInfo(true); setViewDetails(item) }} style={{ margin: -6, marginTop: -6, color: "#949495" }} size="sm" variant="transparent" >
                                                    <i className="fas fa-info-circle"></i>
                                                </Button>
                                            </td>
                                            {/* <td>{item.dateUpdated ? item.dateUpdated.slice(0, 16) : item.dateCreated.slice(0, 16)}</td> */}
                                            {/* <td>{item.projectId}</td> */}
                                            <td>{item.timestampName}</td>
                                            <td>{item.requestedAmount && item.requestedAmount.toLocaleString()}</td>
                                            <td>{item.bankAccount}</td>
                                            <td>{item.bankAccountName}</td>
                                            <td>{item.bankAccountNumber}</td>
                                            <td>{item.totalAmount && item.totalAmount.toLocaleString()}</td>
                                            <td>{item.dateCreated.slice(0, 10)}</td>
                                            <td>
                                                {item.status === "Complete" ?
                                                    <div style={{ color: "green", backgroundColor: "#D9F2E2", borderRadius: 50, textAlign: "center" }}>{item.status}</div>
                                                : item.status === "Cancel" ?
                                                    <div style={{ color: "black", backgroundColor: "lightgrey", borderRadius: 50, textAlign: "center" }}>cancelloooo</div>
                                                :
                                                    <div style={{ color: "grey", backgroundColor: "#FFF6AB", borderRadius: 50, textAlign: "center" }}>{item.status}</div>
                                                }
                                            </td>
                                            <td>
                                                {item.status === "Pending" || item.status === "Cancel" ?
                                                    <Button className="shadow-none ButtonHover" style={{ margin: -6 }} size="sm" variant="transparent" onClick={() => { setShowModalDetails(true); setViewDetails(item) }}>...</Button>
                                                :
                                                    <div></div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr><td colSpan="10" style={{ textAlign: "center", color: "transparent" }}>-</td></tr>
                                }
                            </tbody>
                        </Table>
                        {(() => {
                            if (!toggleShowMoreButton && paymentRequests.length !==0) {
                                return (
                                    <div className="div-buttons">
                                        <Button onClick={handleShowMore} className="shadow-none ButtonGrey ButtonMargin">Show More</Button>
                                        {toggleShowAllButton ?
                                            <Button onClick={handleShowAll} className="shadow-none ButtonGrey ButtonMargin"><i className="fas fa-spinner fa-spin"></i></Button>
                                            :
                                            <Button onClick={handleShowAll} className="shadow-none ButtonGrey ButtonMargin">Show All</Button>
                                        }
                                        <CSVLink style={{ textDecoration: "none", color: "#212529" }} filename={"disbursement.xls"} data={paymentRequestsAll} separator={";"}>
                                            <Button style={{ width: "160px" }} className="shadow-none ButtonGrey ButtonMargin">Export Data (.xls)</Button>
                                        </CSVLink>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
                :
                <i style={{ fontSize: 200 }} className="fas fa-spinner fa-spin"></i>
            }
            <Modal size="lg" scrollable={true} style={{ marginTop: 150 }} show={showModalDetails} onHide={() => setShowModalDetails(false)} >
                <Modal.Header><Modal.Title>Confirmation Transfer Pros</Modal.Title></Modal.Header>
                <Modal.Body className="ModalBody">
                    <div>
                        <div>Project Name<br /><b>{viewDetails.projectName ? viewDetails.projectName : "-"}</b></div>
                        <div>Project ID<br /><b>{viewDetails.projectId ? viewDetails.projectId : "-"}</b></div>
                    </div>
                    <hr  />
                    <div style={{ color: "grey", marginLeft: 30 }}>Transfer To:</div>
                    <div>
                        <div>Bank Name<br /><b>{viewDetails.bankAccount ? viewDetails.bankAccount : "-"}</b></div>
                        <div>Account Number<br /><b>{viewDetails.bankAccountNumber ? viewDetails.bankAccountNumber : "-"}</b></div>
                        <div>Account Name<br /><b>{viewDetails.bankAccountName ? viewDetails.bankAccountName : "-"}</b></div>
                    </div>
                    <div style={{ marginLeft: 30 }}>Total Amount to be Transferred</div>
                    <div style={{ marginLeft: 30 }}><b style={{ color: "red" }}>{viewDetails.totalAmount && viewDetails.totalAmount.toLocaleString()}</b></div>
                    <hr />
                    <div>
                        <div>
                            <div>Upload Payment Proof</div>
                            <input id="uploadButton" type="file" onChange={handleChangeFile} hidden />
                            <Button className="shadow-none ButtonGrey">
                                <label style={{ cursor: "pointer" }} htmlFor="uploadButton" onChange={handleChangeFile}>{!image ? "Upload ..." : "Choose Another File"}</label>
                            </Button>
                        </div>
                        <div>
                            <div>Status Transfer</div>
                            <Dropdown>
                                <Dropdown.Toggle className="shadow-none ButtonGrey">{transferStatus}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setTransferStatus("Pending")}>Pending</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setTransferStatus("Complete")}>Complete</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setTransferStatus("Cancel")}>Cancel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control defaultValue={notes} as="textarea" rows={3} onBlur={handleChangeNotes} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => { setShowModalDetails(false); setImage(null); setNotes(""); setTransferStatus("Pending") }}>Cancel</Button>
                    <Button className="ButtonRed" onClick={() => handleSubmit(viewDetails.id, transferStatus, viewDetails.projectId, viewDetails.requestId)}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" scrollable={true} style={{ marginTop: 150 }} show={showModalInfo} onHide={() => setShowModalInfo(false)} >
                <Modal.Header><Modal.Title>{viewDetails.projectName}</Modal.Title></Modal.Header>
                <Modal.Body className="ModalBody">
                    <div>
                        <div>Retention<br /><b>{viewDetails.retentionAmount ? viewDetails.retentionAmount.toLocaleString() : 0}</b></div>
                        <div>Tax<br /><b>{viewDetails.tax ? viewDetails.tax : 0}</b></div>
                        <div>Request ID<br /><b>{viewDetails.id ? viewDetails.id : "-"}</b></div>
                        <div>Account Bank<br /><b>{viewDetails.bankAccount ? viewDetails.bankAccount : "-"}</b></div>
                        <div>Account Number<br /><b>{viewDetails.bankAccountNumber ? viewDetails.bankAccountNumber : "-"}</b></div>
                        <div>Account Name<br /><b>{viewDetails.bankAccountName ? viewDetails.bankAccountName : "-"}</b></div>
                        <div>Transfer Pros<br /><b>{viewDetails.totalAmount ? viewDetails.totalAmount.toLocaleString() : "-"}</b></div>
                        <div>Fee Arsitag<br /><b>{viewDetails.arsitagFee ? viewDetails.arsitagFee.toLocaleString() : "-"}</b></div>
                        <div>Catatan<br /><b>{viewDetails.notes ? viewDetails.notes : "-"}</b></div>
                        <div>Proof of Payment<br />
                            {viewDetails.receiptFile ?
                                <a href={viewDetails.receiptFile} target="_blank" rel="noopener noreferrer"><b>Lihat Bukti Pembayaran</b></a>
                            :
                                <b>Bukti pembayaran belum diunggah</b>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ButtonGrey" onClick={() => setShowModalInfo(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}