import React, { useState } from "react"
import { Form, Button, Card, Alert, InputGroup } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../actions"
import { isBrowser } from "react-device-detect"

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    
    const dispatch = useDispatch()
    const handleLogIn = () => {
        setError("")
        setLoading(true)
        if (email === "" && password === "") {
            setError("Input email & password")
            setLoading(false)
        } else if (email === "") {
            setError("Input email")
            setLoading(false)
        } else if (password === "") {
            setError("Input password")
            setLoading(false)
        } else {
            dispatch(login({ email, password }, err => {
                setError(err)
                setLoading(false)
            }))
        }
    }

    // const [test, setTest] = useState("")
    // const [test3, setTest3] = useState([["", "", ""], ["", "", ""], ["", "", ""]])
    // const [buttonClicked, setButtonClicked] = useState(false)
    // const testButton = () => {
    //     setButtonClicked(true)
    //     if (test) {
    //         const tempArr = test.split("\n")
    
    //         for (let i=0; i<tempArr.length; i++) {
    //             tempArr[i] = tempArr[i].split("\t")
    //         }
            
    //         // if (tempArr[0].length === 3 && tempArr[1].length === 3 && tempArr[2].length === 3) {
    //             setTest3(tempArr)
    //             setTest("")
    //         // }
    //     }
    // }

    const handleEmail = (e) => {
        e.preventDefault()
        setError("")
        setEmail(e.target.value)
    }
    const handlePassword = (e) => {
        e.preventDefault()
        setError("")
        setPassword(e.target.value)
    }
    const submitEnter = (event) => {
        if (event.keyCode === 13) {
            handleLogIn()
        }
    }

    const { data } = useSelector((state) => {
        return {
            data: state.user.data
        }
    })
    if (data) return <Redirect to="/disbursement" />

    return (
        <div style={{ background: "transparent" }}>
            <Card style={{ height: 325, width: isBrowser ? "33vw" : "85vw", minWidth: 250, borderRadius: 10 }}>
                <Card.Body style={{ padding: 30 }}>
                    <p className="text-center mb-4 mt-4" style={{ fontSize: 30, color: "gray" }}>Log In Account</p>
                    <Form>
                        <Form.Control className="shadow-none" placeholder="Username" type="email" required onChange={handleEmail} onKeyDown={(e) => submitEnter(e)} />
                        <br />
                        <InputGroup>
                            <Form.Control className="shadow-none" placeholder="Password" type={showPassword ? "text" : "password"} required onChange={handlePassword} onKeyDown={(e) => submitEnter(e)} autoCapitalize="none" />
                            <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                                <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                            </InputGroup.Text>
                        </InputGroup>
                        <br />
                        {error ?
                            <Alert variant="danger" style={{ paddingTop: 10, paddingBottom: 10, borderRadius: 2 }}>{error}</Alert>
                            :
                            <>
                                {loading ?
                                    <Button className="shadow-none w-100 login"><i className="fas fa-spinner fa-spin"></i></Button>
                                    :
                                    <Button className="shadow-none w-100 login" onClick={handleLogIn}>Log In</Button>
                                }
                            </>
                        }
                    </Form>
                </Card.Body>
            </Card>
            {/* {!buttonClicked ? */}
                {/* <div style={{ background: "white", padding: 25 }}>
                    <Form.Control as="textarea" rows={3} value={test} onChange={(e) => setTest(e.target.value)} />
                    <Button onClick={() => testButton()}>Test</Button>
                </div> */}
            {/* : */}
                {/* <div style={{ background: "white", padding: 25 }}>
                    {test3.map((item, index) => {
                        return (
                            <InputGroup key={index}>
                                <Form.Control disabled value={item[0]} />
                                <Form.Control disabled value={item[1]} />
                                <Form.Control disabled value={item[2]} />
                            </InputGroup>
                        )
                    })}
                </div> */}
            {/* } */}
        </div>
    )
}