import React from "react"
import Axios from "axios"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Button, Dropdown } from "react-bootstrap"
import { ResponsiveContainer, Line, XAxis, Tooltip, YAxis, ComposedChart, Legend } from "recharts"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ReactExport from "react-data-export"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

export default function DashboardMarketplace() {
    const [mainData, setMainData] = React.useState([
        {
            title: "Point Blast",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Point Blast" }
            ],
            data: []
        },
        {
            title: "Blast",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Blast" },
                { title: "Total Valid Blast" }
            ],
            data: []
        },
        {
            title: "Bid Point",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Point" },
                { title: "Total Bid" }
            ],
            data: []
        },
        {
            title: "Bid Pros",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Bid Unique Pros" },
                { title: "Total Bid Non-Partner Pros" }
            ],
            data: []
        },
        {
            title: "Bonus Point",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Bonus" }
            ],
            data: []
        },
        {
            title: "Purchased Point",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Purchased Point" }
            ],
            data: []
        },
        {
            title: "Purchased Transaction",
            chart: [{ month: "" }],
            columns: [
                { title: "Year" },
                { title: "Month" },
                { title: "Total Purchased Transaction", width: { wpx: 175 } }
            ],
            data: []
        },
        {
            title: "Purchased Value Per Transaction",
            chart: [{ month: "" }],
            data1: {
                title: "Purchased Value Per Transaction",
                columns: [
                    { title: "Year" },
                    { title: "Month" },
                    { title: "Day" },
                    { title: "Nama", width: { wpx: 200 } },
                    { title: "Email", width: { wpx: 250 } },
                    { title: "Value" },
                    { title: "Point Type" },
                    { title: "Value Point" },
                    { title: "Payment Type", width: { wpx: 150 } }
                ],
                data: []
            },
            data2: {
                title: "Purchased Value Per Professional",
                columns: [
                    { title: "Year" },
                    { title: "Month" },
                    { title: "Day" },
                    { title: "Nama", width: { wpx: 200 } },
                    { title: "Email", width: { wpx: 250 } },
                    { title: "Value" },
                    { title: "Point Type" },
                    { title: "Value Point" },
                    { title: "Total Value Point Purchased", width: { wpx: 175 } },
                    { title: "Payment Type", width: { wpx: 150 } }
                ],
                data: []
            }
        }
    ])
    const [dateRange, setDateRange] = React.useState([null, null])
    const [startDate, endDate] = dateRange

    const getData = (date, getWhich) => {
        if (getWhich === "getAll" || getWhich === "getData1") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-lead-point", date).then(res => {
                let newArr = [...mainData]
                newArr[0].data = []
                res.data.data.forEach((item) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    newArr[0].data.push([
                        { value: item.year },
                        { value: item.month_name },
                        { value: item.total_point }
                    ])
                })
                newArr[0].chart = res.data.data
                setMainData(newArr)
                console.log(newArr)
            })
        }

        if (getWhich === "getAll" || getWhich === "getData2") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-lead", date).then(res => {
                const res1 = res.data.data
                Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-lead-v2", date).then(res => {
                    let newArr = [...mainData]
                    newArr[1].data = []
                    res1.forEach((item, index) => {
                        item.month_name_short = item.month_name.substring(0, 3)
                        item.total_valid_blast = res.data.data[index] ? res.data.data[index].total_valid_blast : 0
                        newArr[1].data.push([
                            { value: item.year },
                            { value: item.month_name },
                            { value: item.total_blast },
                            { value: item.total_valid_blast }
                        ])
                    })
                    newArr[1].chart = res1
                    setMainData(newArr)
                })
            })
        }

        if (getWhich === "getAll" || getWhich === "getData3") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-bid", date).then(res => {
                const res1 = res.data.data
                Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-bid-point", date).then(res => {
                    let newArr = [...mainData]
                    newArr[2].data = []
                    res1.forEach((item, index) => {
                        item.month_name_short = item.month_name.substring(0, 3)
                        item.total_point = res.data.data[index].total_point
                        newArr[2].data.push([
                            { value: item.year },
                            { value: item.month_name },
                            { value: item.total_point },
                            { value: item.total_bid }
                        ])
                    })
                    newArr[2].chart = res1
                    setMainData(newArr)
                })
            })
        }

        if (getWhich === "getAll" || getWhich === "getData4") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-unique-professional", date).then(res => {
                const res1 = res.data.data
                Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-unique-professional-non-partner", date).then(res => {
                    let newArr = [...mainData]
                    newArr[3].data = []
                    res1.forEach((item, index) => {
                        item.month_name_short = item.month_name.substring(0, 3)
                        item.total_unique_non_partner_pros = res.data.data[index] ? res.data.data[index].total_unique_non_partner_pros : 0
                        newArr[3].data.push([
                            { value: item.year },
                            { value: item.month_name },
                            { value: item.total_unique_pros },
                            { value: item.total_unique_non_partner_pros }
                        ])
                    })
                    newArr[3].chart = res1
                    setMainData(newArr)
                })
            })
        }

        if (getWhich === "getAll" || getWhich === "getData5") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-bonus-point", date).then(res => {
                let newArr = [...mainData]
                newArr[4].data = []
                res.data.data.forEach((item) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    newArr[4].data.push([
                        { value: item.year },
                        { value: item.month_name },
                        { value: item.total_bonus }
                    ])
                })
                newArr[4].chart = res.data.data
                setMainData(newArr)
            })
        }

        if (getWhich === "getAll" || getWhich === "getData6") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-point", date).then(res => {
                let newArr = [...mainData]
                newArr[5].data = []
                res.data.data.forEach((item) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    newArr[5].data.push([
                        { value: item.year },
                        { value: item.month_name },
                        { value: item.total_point_purchased }
                    ])
                })
                newArr[5].chart = res.data.data
                setMainData(newArr)
            })
        }

        if (getWhich === "getAll" || getWhich === "getData7") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-trx", date).then(res => {
                let newArr = [...mainData]
                newArr[6].data = []
                res.data.data.forEach((item) => {
                    item.month_name_short = item.month_name.substring(0, 3)
                    newArr[6].data.push([
                        { value: item.year, style: { alignment: { horizontal: "left" } } },
                        { value: item.month_name },
                        { value: item.total_purchased_trx, style: { alignment: { horizontal: "left" } } }
                    ])
                })
                newArr[6].chart = res.data.data
                setMainData(newArr)
            })
        }

        if (getWhich === "getAll" || getWhich === "getData8") {
            Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-trx", date).then(res => {
                const res1 = res.data.data
                Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-value", date).then(res => {
                    let newArr = [...mainData]
                    newArr[7].data = []
                    res1.forEach((item, index) => {
                        item.month_name_short = item.month_name.substring(0, 3)
                        item.total_purchased_value = res.data.data[index].total_purchased_value
                        item.average_basket_purchase = Math.round(res.data.data[index].total_purchased_value / item.total_purchased_trx)
                    })

                    Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-value-export-per-transaction", date).then(res => {
                        res.data.data.forEach((item) => {
                            // console.log(item)
                            newArr[7].data1.data.push([
                                { value: item.year },
                                { value: item.month },
                                { value: item.day },
                                { value: item.name },
                                { value: item.email },
                                { value: Number(item.value).toLocaleString() },
                                { value: item.point_type },
                                { value: item.point_purchased ? Number(item.point_purchased) : "", style: { alignment: { horizontal: "left" } } },
                                { value: item.payment_type }
                            ])
                        })
                    })
                    Axios.post("https://www.arsitag.com/api/v1/dashboard-marketplace/total-purchase-value-export-per-professional", date).then(res => {
                        res.data.data.forEach((item) => {
                            // console.log(item)
                            newArr[7].data2.data.push([
                                { value: item.year },
                                { value: item.month },
                                { value: item.day },
                                { value: item.name },
                                { value: item.email },
                                { value: Number(item.value).toLocaleString() },
                                { value: item.point_type },
                                { value: item.point_purchased ? Number(item.point_purchased) : "", style: { alignment: { horizontal: "left" } } },
                                { value: Number(item.total_point_purchased), style: { alignment: { horizontal: "left" } } },
                                { value: item.payment_type }
                            ])
                        })
                    })

                    newArr[7].chart = res1
                    setMainData(newArr)
                })
            })
        }
    }

    React.useEffect(() => {
        const today = new Date()
        const dateTo = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)
        today.setMonth(today.getMonth() - 7)
        const dateFrom = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)
        const date = dateFrom + "&" + dateTo

        getData(date, "getAll")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDateRange = (inputDate, getWhich) => {
        setDateRange(inputDate)

        if (inputDate[1] !== null) {
            const dateArr = []
            for (let i = 0; i <= 1; i++) {
                const offset = inputDate[i].getTimezoneOffset()
                inputDate[i] = new Date(inputDate[i].getTime() - (offset * 60 * 1000))
                dateArr.push(inputDate[i].toISOString().split("T")[0])
            }
            if (dateArr.length === 2) {
                const tempYear = dateArr[1].slice(0, 4)
                const tempMonth = dateArr[1].slice(5, 7)[0] === "0" ? dateArr[1].slice(6, 7) : dateArr[1].slice(5, 7)
                dateArr[1] = new Date(tempYear, tempMonth, 0)
                const offset = dateArr[1].getTimezoneOffset()
                dateArr[1] = new Date(dateArr[1].getTime() - (offset * 60 * 1000))
                dateArr[1] = dateArr[1].toISOString().split("T")[0]
                const date = dateArr[0] + "&" + dateArr[1]
                getData(date, getWhich)
                setDateRange([null, null])
            }
        }
    }

    const capitalizeLetter = (input) => {
        return input.split("_").map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ")
    }

    const { userLoggedIn } = useSelector((state) => {
        return {
            userLoggedIn: state.user.data
        }
    })
    if (!userLoggedIn) return <Redirect to="/login" />

    return (
        <div>
            <div className="boxShadow" style={{ height: "80vh", width: "83vw", padding: "35px 40px", borderRadius: 10, background: "white", overflow: "scroll" }}>
                <h2 onClick={() => console.log()}>Marketplace</h2>
                <hr style={{ margin: "35px -40px", color: "lightgrey", padding: 1 }} />
                {mainData.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "50vh", width: "100%" }}>
                                <br /><h4>{item.title}</h4>
                                <div className="d-flex justify-content-between align-items-center" style={{ width: "85%" }}>
                                    <DatePicker
                                        className="boxShadow"
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => handleDateRange(update, "getData" + (index + 1))}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                        placeholderText="Select start and end date"
                                        minDate={new Date("01-01-2022")} maxDate={new Date()}
                                    />
                                    <div className="d-flex justify-content-end" style={{ width: "66vw" }}>
                                        {index !== 7 ?
                                            <ExcelFile element={<Button style={{ width: "160px" }} className="shadow-none ButtonRed">Export (.xlsx)</Button>} filename={item.title}>
                                                <ExcelSheet dataSet={[item]} name="Sheet1" />
                                            </ExcelFile>
                                            :
                                            <Dropdown>
                                                <Dropdown.Toggle style={{ width: "160px" }} className="shadow-none ButtonRed">Export (.xlsx)</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <ExcelFile element={<Dropdown.Item style={{ width: "158px" }}>Per Transaction</Dropdown.Item>} filename={item.data1.title}>
                                                        <ExcelSheet dataSet={[item.data1]} name="Sheet1" />
                                                    </ExcelFile>
                                                    <ExcelFile element={<Dropdown.Item style={{ width: "158px" }}>Per Professional</Dropdown.Item>} filename={item.data2.title}>
                                                        <ExcelSheet dataSet={[item.data2]} name="Sheet1" />
                                                    </ExcelFile>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                    </div>
                                </div>
                                <ResponsiveContainer width="100%" height="75%">
                                    <ComposedChart margin={{ top: 20, right: 75, bottom: 0, left: 30 }} data={item.chart}>
                                        <XAxis dataKey="month_name_short" />
                                        <YAxis tickFormatter={tick => { return tick.toLocaleString() }} domain={index === 2 ? [0, 40000] : index === 4 ? [0, 4000] : index === 7 ? [0, 40000000] : [0, "auto"]} />
                                        <Tooltip separator=": " formatter={(value, name) => [Number(value).toLocaleString(undefined, { maximumFractionDigits: 0 }), capitalizeLetter(name)]} />
                                        <Legend verticalAlign="top" align="right" formatter={(value) => capitalizeLetter(value)} />
                                        {
                                            index === 0 ? <>
                                                <Line type="monotone" animationBegin={20} dataKey="total_point" stroke="purple" strokeWidth={2} />
                                            </> :
                                                index === 1 ? <>
                                                    <Line type="monotone" dataKey="total_blast" stroke="purple" strokeWidth={2} />
                                                    <Line type="monotone" dataKey="total_valid_blast" stroke="orange" strokeWidth={2} />
                                                </> :
                                                    index === 2 ? <>
                                                        <Line type="monotone" dataKey="total_point" stroke="purple" strokeWidth={2} />
                                                        <Line type="monotone" dataKey="total_bid" stroke="orange" strokeWidth={2} />
                                                    </> :
                                                        index === 3 ? <>
                                                            <Line type="monotone" dataKey="total_unique_pros" stroke="purple" strokeWidth={2} />
                                                            <Line type="monotone" dataKey="total_unique_non_partner_pros" stroke="orange" strokeWidth={2} />
                                                        </> :
                                                            index === 4 ? <>
                                                                <Line type="monotone" dataKey="total_bonus" stroke="purple" strokeWidth={2} />
                                                            </> :
                                                                index === 5 ? <>
                                                                    <Line type="monotone" dataKey="total_point_purchased" stroke="purple" strokeWidth={2} />
                                                                </> :
                                                                    index === 6 ? <>
                                                                        <Line type="monotone" dataKey="total_purchased_trx" stroke="purple" strokeWidth={2} />
                                                                    </> :
                                                                        <>
                                                                            <Line type="monotone" dataKey="total_purchased_value" stroke="purple" strokeWidth={2} />
                                                                            <Line type="monotone" dataKey="average_basket_purchase" stroke="orange" strokeWidth={2} />
                                                                        </>
                                        }
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                            {index !== 7 ?
                                <>
                                    <br /><br /><br /><br /><br /><br /><br />
                                </>
                                :
                                <>
                                    <br /><br />
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}